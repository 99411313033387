/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.spots').controller('FriendsDialogController', ['$state', '$mdDialog', '$q', 'SecurityAccountResourceFactory', 'loginService', 'currentFriends', 'accountFriends', function ($state, $mdDialog, $q, SecurityAccountResourceFactory, loginService, currentFriends, accountFriends) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.friends = null;
    vm.assign = {};
    vm.disableSave = false;
    vm.selectedFriends = [];
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.selectFriend = selectFriend;
    vm.selectCurrentFriends = selectCurrentFriends;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save() {
      var friendsToReturn = [];

      for (var i = 0; i < vm.selectedFriends.length; i++) {
        friendsToReturn.push(vm.friends[vm.selectedFriends[i]]);
      }

      $mdDialog.hide(friendsToReturn);
    }

    function selectFriend(friend, index, event) {
      vm.currFriends = [];

      if (vm.lastSelection === index) {
        vm.selectedFriends.splice(vm.selectedFriends.indexOf(index), 1);
        vm.lastSelection = null;
        return;
      }

      if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
        vm.selectedFriends = [];
      }

      if (event.shiftKey && vm.lastSelection > -1) {
        // Do multiselect
        var toIndex = Math.max(index, vm.lastSelection);
        var fromIndex = Math.min(index, vm.lastSelection);

        for (var i = fromIndex; i <= toIndex; i++) {
          if (vm.selectedFriends.indexOf(i) < 0) {
            vm.selectedFriends.push(i);
          }
        }
      } else if (vm.selectedFriends.indexOf(index) < 0) {
        vm.selectedFriends.push(index);
      } else {
        vm.selectedFriends.splice(vm.selectedFriends.indexOf(index), 1);
      }

      vm.lastSelection = index;
    }

    function selectCurrentFriends(friend, index) {
      if (vm.currFriends) {
        for (var i = 0; i < vm.currFriends.length; i++) {
          if (vm.currFriends[i].id == friend.id) {
            vm.selectedFriends.push(index);
            vm.lastSelection = index;
            return true;
          }
        }
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish the

    /**
     * Initialization for this controller.
     */


    function _init() {
      vm.currFriends = angular.copy(currentFriends);
      vm.friends = angular.copy(accountFriends);
    }
  }]);
})();